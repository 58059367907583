import { Link } from "gatsby"
import React from "react"
import {
  BlogCategoryType,
  ContentfulBlogArticleType,
} from "../../types/blog-types"
import ContentfulRichText from "../../utilities/contentful-rich-text"
import BlogArticleBox from "./blog-article-box"
import BlogArticleHorizontalBox from "./blog-article-horizontal-box"

const BlogArticlesPagination = ({
  pageInfo,
  categorySlug,
}: {
  pageInfo: ContentfulBlogArticleType["allContentfulBlogArticle"]["pageInfo"]
  categorySlug?: string
}) => {
  const pages = Math.ceil(pageInfo.totalCount / pageInfo.perPage)
  let url = categorySlug ? `/blog/${categorySlug}` : "/blog"
  return (
    <div className='blog-articles-pagination-container flex space-x-5 justify-center'>
      {Array.from({ length: pages }, (_, i) => (
        <Link
          to={i === 0 ? url : `${url}/${i + 1}`}
          className={`blog-articles-page${
            pageInfo.currentPage === i + 1 ? " current-page" : ""
          }`}
          key={i}
        >
          {i + 1}
        </Link>
      ))}
    </div>
  )
}

const BlogCategoryDescription = ({ category }) => {
  if (!category.id) return null

  return (
    <div className='xl:pt-20'>
      <h1 className='text-[28px] xl:text-[48px] pt-20 pb-10 xl:pt-0 font-bold capitalize'>
        {category.name.toLowerCase()}
      </h1>
      <div className='text-[16px]'>
        <ContentfulRichText raw={category.description} />
      </div>
    </div>
  )
}

const BlogArticles = ({
  data,
  pageInfo,
  categorySlug,
  category,
}: {
  data: ContentfulBlogArticleType["allContentfulBlogArticle"]["edges"]
  pageInfo: ContentfulBlogArticleType["allContentfulBlogArticle"]["pageInfo"]
  categorySlug?: string
  category?: BlogCategoryType
}) => {
  return (
    <div className='blog-articles-container'>
      {/* {categorySlug && <BlogCategoryDescription category={category} />} */}

      <BlogArticleHorizontalBox data={data[0].node} />

      <div className='blog-articles-two-container'>
        {data.slice(1).map(({ node: article }, index) => {
          if (index >= 2) return null

          return <BlogArticleBox article={article} key={article.id} />
        })}
      </div>

      <div className='blog-articles-three-container'>
        {data.slice(3).map(({ node: article }, index) => {
          return (
            <BlogArticleBox
              smallImage={true}
              article={article}
              key={article.id}
            />
          )
        })}
      </div>

      <BlogArticlesPagination pageInfo={pageInfo} categorySlug={categorySlug} />
    </div>
  )
}

export default BlogArticles
